import React from 'react'
import { Link, Outlet, useLocation, useParams } from 'react-router-dom'


import DATA from '../data/projects-data'; 


const Projelerimiz = () => {
  const params = useParams()
  console.log(params)


  const projectData = DATA.find(item => item.type === params.id);

  return (
    <div className=' bg-[#E9E9E9] grid grid-cols-12 md:grid-cols-8 pt-24 pb-24'>
      <div className='col-start-2 col-end-12 md:col-start-2 md:col-end-8 h-full '>
        <div className=' flex flex-col md:flex-row w-full items-center justify-center space-x-5 pt-20 pb-10'>

          <Link to={'tamamlanan-projeler'} className={`text-28  ${params.id==='tamamlanan-projeler'?' font-semibold':'font-light'}`}>Tamamlanan Projelerimiz</Link>
          <div className='h-10 w-px bg-black'></div>
          <Link to={'devam-eden-projeler'} className={`text-28   ${params.id==='devam-eden-projeler'?' font-semibold':'font-light'}`}>Devam Eden Projelerimiz</Link>

        </div>
        <Outlet context={{ projectData }} />

      </div>
    </div>
  )
}

export default Projelerimiz