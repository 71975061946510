import React from 'react'
import { Link } from 'react-router-dom'
import { motion } from "framer-motion"
const AboutUs = () => {

  const list = {
    visible: {
      opacity: 1,

      transition: {
        when: "beforeChildren",
        staggerChildren: 0.3,

      },
    },
    hidden: { opacity: 0 },

  }

  const itemText = { visible: { opacity: 1, transition: { duration: 1 } }, hidden: { opacity: 0 }, }
  return (
    <motion.div initial="hidden" whileInView="visible" variants={list} className=' w-full  grid grid-cols-12 pt-20 pb-40'>
      <div className='col-start-2 col-end-12 md:col-start-3 md:col-end-11  '>
        <div className='grid grid-cols-1 md:grid-cols-2 relative'>
          <motion.img variants={itemText} className='absolute md:relative' src='/images/back-logo.svg' />
          <div className='h-full w-full '>
            <motion.h4 variants={itemText} className='text-55 font-bold mb-5'>Hakkımızda</motion.h4>
            <motion.p variants={itemText} className='text-18 text-customGray mb-5 !leading-6'>
            İnanç İnşaat olarak, 1994 yılında müteahhitlik ve taahhüt işlerine adım atan firmamız, kurucularımız Rasim İnanç ve Hüsamettin İnanç'ın liderliğinde güçlü bir yapı oluşturmuştur. Çevik ve çalışkan kardeşler, uzman kadrolarıyla birlikte güven inşa etmeyi en büyük hedefleri olarak benimsemişlerdir. 
            </motion.p>
            <motion.p variants={itemText} className='text-18 text-customGray !leading-6'>
            Yıllardır süregelen projelerimizi titizlikle, kaliteden ödün vermeden ve zamanında teslim ederek müşterilerimizin takdirini kazanmış bulunmaktayız. Projelerimiz, Denizli, Manisa, Konya, Muğla ve Tekirdağ illerinde çeşitli altyapı ve üstyapı çalışmalarını kapsamaktadır. Bu projeler arasında ikamet amaçlı konutlar, okullar, hastaneler ve hastane güçlendirmeleri, düğün salonları, tarımsal sulama işleri, içme suyu inşaatı ve içme suyu depoları, idari bina ve laboratuvarlar, olimpik kapalı yüzme havuzları ve spor kompleksleri, toplantı salonları, SSB ve RCC beton yollar, asfalt ve beton yollar yer almaktadır.
            </motion.p>
            <motion.div variants={itemText}>
              <Link to={'/hakkimizda'} className='  inline-block pt-8 transition-all duration-500 group hover:scale-105'>
                <div className=' py-2 px-8 border-[1.5px] border-customGray group-hover:bg-inancRed group-hover:text-white bg-white text-14 rounded-2xl text-customGray transition-all duration-500'>DAHA FAZLASI İÇİN</div>
              </Link>
            </motion.div>
          </div>

        </div>


      </div>

    </motion.div>
  )
}

export default AboutUs