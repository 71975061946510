import React from 'react'
import { Link, useLocation } from 'react-router-dom'
const Footer = () => {
    return (
        <div className=' w-full bg-inancRed grid grid-cols-12 md:grid-cols-8 py-12'>
            <div className='col-start-2 col-end-12 md:col-start-2 md:col-end-8 grid grid-cols-1 md:grid-cols-4 h-full  '>
                <div className=' w-full    flex items-center'>
                    <img className='w-36' src='/images/inanc-white-logo.svg' />
                </div>
                <div className=' w-full    flex flex-col justify-between space-y-5 !text-14 text-white mt-10 md:mt-0'>
                    <Link to={'/'}>ANASAYFA</Link>
                    <Link to={'hakkimizda'}>HAKKIMIZDA</Link>

                    <Link to={'projelerimiz/tamamlanan-projeler'}>PROJELERİMİZ</Link>
                    <Link to={'iletisim'}>İLETİŞİM</Link>

                </div>
                <div className=' w-full    flex flex-col text-16 text-white md:mt-0 mt-10'>
                    <h5>İletişim</h5>
                    <a href='https://maps.app.goo.gl/VmNLz57CH1eRpMwz5'>
                    <div className='mb-3'>
                       <p>Sırakapılar Mah. 1526 Sok. </p>
                        <p>No: 2 D: 3

                            Pamukkle / DENİZLİ</p>
                    </div>
                    </a>
                    <a href='tel:+902582634658'><p className=''>+90 258 263 46 58</p></a>
                    <a href='mailto:info@inancinsaat.com'><p >info@inancinsaat.com</p></a>
                </div>


                <div className='h-full w-full flex flex-col justify-between md:items-end  mt-10 md:mt-0'>
                    <div onClick={() => window.scrollTo(0, 0)} className='hidden md:block cursor-pointer'><img src='/images/arrow.svg' /></div>

                    <div className='flex space-x-5  w-full justify-start md:justify-center'>
                        <a href=''><img className='h-6' src='/images/social-media/facebook.svg' /></a>
                        <a><img className='h-6' src='/images/social-media/instagram.svg' /></a>


                    </div>
                    <div></div>

                </div>


            </div>

        </div>
    )
}

export default Footer