import React from 'react'
import { Outlet } from 'react-router-dom'
import Header from '../components/Header'
import Footer from '../components/Footer'
import SubFooter from '../components/SubFooter'

const PageContainer = () => {
  return (
    <>  
      
      <Header/>
       <Outlet/>
       <Footer/>
       <SubFooter/>
       
       
    </>
  )
}

export default PageContainer