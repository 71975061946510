import React from 'react'

const SubFooter = () => {
    return (
        <div className=' w-full bg-white grid grid-cols-12 md:grid-cols-8 py-5'>
            <div className='col-start-2 col-end-12 md:col-start-2 md:col-end-8 flex justify-between h-full  items-center text-14 flex-col sm:flex-row'>
                <p>© 2024 - İnanç İnşaat Tüm Hakları Saklıdır.</p>
                <a href='https://macart.com.tr/'><p>MAC ART</p></a>
            </div>
        </div>
    )
}

export default SubFooter