import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom';
import DATA from '../../data/projects-data';


import Lightbox from 'yet-another-react-lightbox'
import "yet-another-react-lightbox/styles.css";
import { Zoom } from 'yet-another-react-lightbox/plugins';


// import required modules
import { FreeMode, Navigation, Thumbs } from 'swiper/modules';


const ProjelerimizDetay = () => {



    const [isOpen, setIsOpen] = useState(false);
    const [currentImageIndex, setCurrentImageIndex] = useState(null);
    const handleImageClick = (index) => {
      setCurrentImageIndex(index);
      setIsOpen(true);
    };


  const location = useLocation()

  const path = location.pathname;

  const projectId = path.split('/')
  const splitedUrl2 = projectId[2]
  const splitedUrl3 = projectId[3]


  // Filtreleme işlemini yap
  const filteredByType = DATA.filter((e) => e.type === splitedUrl2);

  // Eğer eşleşen öğe varsa, onun 'data' özelliğine eriş
  const filteredData = filteredByType.length > 0
    ? filteredByType[0].data.filter((e) => e.link === splitedUrl3)[0]
    : undefined;

  console.log(filteredData)
  return (
    <div className='flex'>
      {
        filteredData ?
          <div className='flex flex-col w-full'>
            <div className='  grid grid-cols-12 md:grid-cols-8 pt-24'>
              <div className='col-start-2 col-end-12 md:col-start-2 md:col-end-8 '>
                <h5 className='pt-10 pb-5 text-22 font-bold'>{filteredData.title}</h5>

                <div className='  space-y-5'>
                    <div className='h-[60vh] '>
                    <img src={filteredData.detailData.img} className='w-full h-full object-cover'/>
                    </div>
                    <div className='grid grid-cols-2  md:grid-cols-4 gap-5 '>
                        {
                            filteredData.detailData.data.map((item,index)=>{
                                return(
                                <div onClick={() => handleImageClick(index)}  className='relative group overflow-hidden cursor-pointer flex items-center justify-center hover:scale-95 transition-all duration-1000 ease-in-out'>
                                                            <div className='h-full w-full bg-[#98002f] absolute top-0 left-0 mix-blend-hard-light z-30 opacity-0 group-hover:opacity-100 transition-all duration-500'></div>
                                                            <img className='absolute z-40 w-[12%]  opacity-0 group-hover:opacity-100 transition-all duration-500' src='/images/magnifying-glass.svg'/>
                                    <img src={item} className=' w-full h-full object-cover'/>
                                </div> 
                                )
                            })
                        }

                    </div>
                </div>


                <div className=' w-full  mt-10 mb-10 flex justify-between flex-col md:flex-row space-y-5 md:space-y-0'>
                  <p className=''>İNANÇ İnş. Taah. Nak. Mim. Müh. San. ve Tic. Ltd. Şti. Taahhüdünde Tamamlanmıştır.
                  </p>
                  <p className='text-end'>2021</p>
                </div>

              </div>

            </div>

            {isOpen && (
        <Lightbox
          open={isOpen}
          close={() => setIsOpen(false)}
          slides={filteredData.detailData.data.map((item,index) => ({ src: item, alt: item }))}
          index={currentImageIndex}
          plugins={[Zoom]}
        />
      )}







          </div>
          :
          <div className='h-screen bg-blue-300'></div>

      }

    </div>

  )
}

export default ProjelerimizDetay



