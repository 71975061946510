import React from 'react'
import { Link } from 'react-router-dom'
import { motion } from "framer-motion"
const Hakkimizda = () => {
  const list = {
    visible: {
        opacity: 1,
  
        transition: {
            when: "beforeChildren",
            staggerChildren: 0.3,
            
        },
    },
    hidden: { opacity: 0 },
  
  }
  
  const itemText = { visible: { opacity: 1, transition: { duration: 1 } }, hidden: { opacity: 0 }, }
  return (
    <motion.div  initial="hidden" whileInView="visible" variants={list}  className='  grid grid-cols-12 md:grid-cols-6 pt-24'>
      <div className='col-start-2 col-end-12 md:col-start-2 md:col-end-6  '>
        <div className='mt-20 mb-20'>
          <motion.h4 variants={itemText} className='md:text-center  text-35 mb-5'>Hakkımızda</motion.h4>
          <motion.p variants={itemText} className='text-18 text-black font-bold mb-5'>Geleceği Güvenle İnşa Ediyoruz.
          </motion.p>
          <motion.p variants={itemText} className='text-18 text-customGray mb-5'>İnanç İnşaat olarak, 1994 yılında müteahhitlik ve taahhüt işlerine adım atan firmamız, kurucularımız Rasim İnanç ve Hüsamettin İnanç'ın liderliğinde güçlü bir yapı oluşturmuştur. Çevik ve çalışkan kardeşler, uzman kadrolarıyla birlikte güven inşa etmeyi en büyük hedefleri olarak benimsemişlerdir. </motion.p>
          <motion.p variants={itemText} className='text-18 text-customGray mb-5'>Yıllardır süregelen projelerimizi titizlikle, kaliteden ödün vermeden ve zamanında teslim ederek müşterilerimizin takdirini kazanmış bulunmaktayız. Projelerimiz, Denizli, Manisa, Konya, Muğla ve Tekirdağ illerinde çeşitli altyapı ve üstyapı çalışmalarını kapsamaktadır. Bu projeler arasında ikamet amaçlı konutlar, okullar, hastaneler ve hastane güçlendirmeleri, düğün salonları, tarımsal sulama işleri, içme suyu inşaatı ve içme suyu depoları, idari bina ve laboratuvarlar, olimpik kapalı yüzme havuzları ve spor kompleksleri, toplantı salonları, SSB ve RCC beton yollar, asfalt ve beton yollar yer almaktadır.
          </motion.p>
          <motion.p variants={itemText} className='text-18 text-customGray mb-5'>İnanç İnşaat olarak, taahhüt işlerindeki faaliyetlerimizi bu çeşitlilik ve geniş kapsamda sürdürmekteyiz. Amacımız, her projede güveni ve kaliteyi ön planda tutarak sektördeki güçlü konumumuzu korumak ve daha ileriye taşımaktır.
          </motion.p>
          <motion.p variants={itemText} className='text-18 text-black font-bold '>Projelerimizi gerçekleştirirken benimsediğimiz temel ilkelerimiz:</motion.p>


          <motion.p variants={itemText} className='text-18 text-customGray '><span className='font-bold text-black'>Kalite: </span> Her aşamada yüksek standartlarda işçilik ve malzeme kullanımı.
          </motion.p>
          <motion.p variants={itemText} className='text-18 text-customGray '><span className='font-bold text-black'>Güven: </span>Tüm paydaşlarımızla dürüst ve şeffaf bir iş birliği içinde olma.

          </motion.p>
          <motion.p variants={itemText} className='text-18 text-customGray '><span className='font-bold text-black'>Zamanında Teslimat: </span>Projeleri belirlenen süre içinde tamamlama.

</motion.p>
<motion.p variants={itemText} className='text-18 text-customGray mb-5'><span className='font-bold text-black'>Müşteri Memnuniyeti: </span>Müşteri ihtiyaçlarını ve beklentilerini en üst düzeyde karşılama.
</motion.p>
<motion.p variants={itemText} className='text-18 text-customGray mb-5'>İnanç İnşaat, deneyimli ekibi ve yenilikçi yaklaşımı ile Türkiye'nin önde gelen inşaat firmalarından biri olma yolunda emin adımlarla ilerlemektedir. Faaliyet gösterdiğimiz tüm alanlarda en iyi hizmeti sunarak, geleceğin yapılarını inşa etmeye devam edeceğiz.
</motion.p>
<motion.p variants={itemText} className='text-18 text-customGray mb-5'>Ayrıca, yurt dışı projelerine de hazır olduğumuzu gururla belirtmek isteriz. Uluslararası standartlarda inşaat projeleri gerçekleştirmek için gerekli tüm organizasyonları ve hazırlıkları tamamlamış bulunmaktayız. Global pazarda da güven ve kalite ilkelerimizle yer almayı hedefliyor, yurt dışında da projelerimizi başarıyla hayata geçirmeye hazırız.
</motion.p>
{/* <motion.p variants={itemText} className='text-18 text-customGray '>Siz de güven, kalite ve zamanında teslimat konularında en üst düzeyde hizmet almak istiyorsanız, İnanç İnşaat ile tanışın. Projelerinizde sizinle iş birliği yapmaktan mutluluk duyarız.

</motion.p> */}
        </div>
        
        {/* <div className='grid grid-cols-1 md:grid-cols-2 gap-20   my-20 '>
          <motion.div variants={itemText} className='flex justify-center md:justify-end'>
            <div className='flex items-center flex-col'>
          <img className='h-48' src='/images/about/personal.svg'/>
          <h6 className='text-14 text-customGray mt-3'>Rasim İnanç</h6>
          </div>
          </motion.div>
          <motion.div variants={itemText} className='flex justify-center md:justify-start'>
          <div className='flex items-center flex-col'>
          <img className='h-48' src='/images/about/personal.svg'/>
          <h6 className='text-14 text-customGray mt-3'>Hüsamettin İnanç </h6>
          </div>
          </motion.div>

        </div> */}
        <div className='grid grid-cols-1 md:grid-cols-2 gap-20 mb-40'>
          <div className=''>
          <motion.h4 variants={itemText} className='  text-35 mb-5'>Vizyon</motion.h4>
          <motion.p variants={itemText} className='text-18 text-customGray'>Türkiye'de ve yurt dışında güvenilir, yenilikçi bir inşaat firması olmak. Kalite, güven ve müşteri memnuniyeti odaklı çalışmalarımızla sektördeki konumumuzu sürekli olarak geliştirmeyi hedefliyoruz. Dinamik ekibimizle, global arenada da örnek alınan bir firma olma yolunda ilerliyoruz.</motion.p>
          </div>
          <div className=''>
          <motion.h4 variants={itemText} className=' text-35 mb-5'>Misyon</motion.h4>
          <motion.p variants={itemText} className='text-18 text-customGray'>Yüksek standartlarda inşaat projeleri gerçekleştirerek, müşterilerimize güvenilir, kaliteli ve zamanında hizmet sunmak. Hem yurtiçinde hem de yurtdışında güven inşa ederek, sektördeki güçlü konumumuzu koruyup daha da ileriye taşımayı misyon ediniyoruz.</motion.p>
            </div>

        </div>

      </div>
    </motion.div>


  )
}

export default Hakkimizda