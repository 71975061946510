const DATA = [
    {
      title: 'ANASAYFA',
      link: '/'
    },
    {
      title: 'HAKKIMIZDA',
      link: '/hakkimizda',
      //   dropDown: [
      //     {
      //       title: 'Hakkımızda',
      //       link: '/hakkimizda',
      //     },
      //     {
      //       title: 'Vizyon',
      //       link: '/hakkimizda',
      //     },
      //     {
      //       title: 'Misyon',
      //       link: '/hakkimizda',
      //     },
  
  
      //   ]
  
    },
    {
      title: 'PROJELERİMİZ',
      link: '/projelerimiz/tamamlanan-projeler',
      dropDown: [
        {
          title: 'Tamamlanan Projelerimiz',
          link: '/projelerimiz/tamamlanan-projeler',
        },
  
        {
          title: 'Devam Eden Projelerimiz',
          link: '/projelerimiz/devam-eden-projeler',
  
        },
      ]
    },
    {
      title: 'İLETİŞİM',
      link: '/iletisim'
    },
  
    // {
    //   title: 'TR',
  
    //   dropDown: [
    //     {
    //       title: 'EN',
  
    //     },
  
    //   ]
  
    // },
  ]
  


  export default DATA;