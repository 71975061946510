import { motion, transform, useTransform, useViewportScroll } from "framer-motion"
import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'


import DATA from '../data/header-data';



const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1024);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return isMobile;
};







const Header = () => {
  const location = useLocation()
  const [show, setShow] = useState(false)
  const [toggle, setToggle] = useState(false)

  useEffect(() => {
    if (location.pathname === '/') {
      setShow(false)
    }
    else (
      setShow(true)
    )
  }, [location])




  useEffect(() => {
    setToggle(false)
  }, [location])


  const { scrollY } = useViewportScroll();
  const bgOpacity = useTransform(scrollY, [0, 2 * 16], [0, 1]);
  const bgColor = useTransform(
    scrollY,
    [0, 2 * 16],
    ["rgba(0, 0, 0, 0)", "rgba(0, 0, 0, 1)"]
  );


  const icon2Height = useTransform(
    scrollY,
    [0, 2 * 16],
    ['10rem', '5rem']
  );


  const icon3Height = useTransform(
    scrollY,
    [0, 2 * 16],
    ['5rem', '4rem']
  );

  const icon2Opacity = useTransform(
    scrollY,
    [0, 2 * 16],
    [1, 0]
  );

  const xzzz = useTransform(
    scrollY,
    [0, 2 * 1],
    ['100%', '0%']
  );

  const yzzz = useTransform(
    scrollY,
    [0, 2 * 1],
    ['4rem', '0rem']
  );
  const yxopacity = useTransform(
    scrollY,
    [0, 2 ,2,2],
    [1, 0,0,1]
  );


  const isMobile = useIsMobile();

  // Create a transform based on the scrollY value

  return (
    <div className={`${show ? 'block' : 'hidden'}  `}>

<header className="flex justify-center   fixed w-full   left-0 z-[100] ">
      <motion.div className="w-full h-[5.5rem] 3xl:container  fixed  flex items-center p-0  !bg-white border-b border-b-customGray1 ">

        <div className='grid grid-cols-12 lg:grid-cols-8 w-full'>
          <div className=' lg:px-0 col-start-2 col-end-12  lg:col-start-2  lg:col-end-8    '>
            <div className='grid grid-cols-2 lg:grid-cols-3  '>
            <div className="flex  relative z-100 w-full items-center h-full">
              <Link to={'/'} className=' block'>
                <img className='w-36' src='/images/inanc-black-logo.svg' /></Link>

            </div>


      
            <div  onClick={() => setToggle(!toggle)} className='lg:hidden flex w-full justify-end items-center'>
                <div className='h-5 w-8  flex items-center justify-center relative'>
                    <div className={`w-8 h-0.5  bg-black absolute top-0 transition-all duration-500 ${!toggle?'top-0':'top-2.5'}  ${!toggle?'rotate-0':'rotate-45'}`} ></div>
                    <div className={`w-8 h-0.5 bg-black ${!toggle?'top-0':'top-2.5'} absolute transition-all duration-500  ${!toggle?'top-2.5':'top-2.5'} ${!toggle?'translate-x-0':'translate-x-96'}` }></div>
                    <div className={`w-8 h-0.5 bg-black ${!toggle?'top-0':'top-2.5'} absolute bottom-0 transition-all duration-500  ${!toggle?'top-5':'top-2.5'} ${!toggle?'rotate-0':'-rotate-45'}`}></div>

                </div>
            </div>



            <nav className="lg:flex items-center  hidden  justify-end ">

              <ul className='flex  h-full'>
                {
                  DATA.map((item, index) => {
                    return (
                      <li key={index} className='group cursor-pointer relative flex  items-center  px-4 transition-all duration-500 '>
                        <Link to={item.link}><motion.h6 className={`${item.link === location.pathname ? '!text-customGray2' : 'text-redloryColor'} flex  group-hover:!text-tedakblue !text-14 openSans font-semibold hover:!text-[#FF7676] transition-all duration-500`}>{item.title}

                          {/* {item.dropDown && 
                    
                    (
                      
                      
                      <motion.img style={{ filter: ` invert(${iconFilterInvert})` }} className='ml-2 transform group-hover:rotate-90 transition-all duration-500 ' src='/image/header/miniHook.svg' />
                      
                      
                      )} */}
                        </motion.h6></Link>
                        {
                          item.dropDown &&
                          <ul className='absolute opacity-0 top-[4.2rem] left-0 invisible group-hover:visible  bg-white  group-hover:opacity-100  overflow-hidden   transition-all duration-500 w-max shadow-sm'>
                            {
                              item.dropDown.map((item, index) => {
                                return (
                                  <li key={index} className='group/innerDropDown '>
                                    <Link to={item.link}><motion.h6  className={` px-4 py-2 flex group-hover/innerDropDown:!text-tedakblue !text-14 openSans font-semibold hover:!bg-customGray2`}> {item.title}{item.dropDown && (<img className='ml-2 transform group-hover/innerDropDown:rotate-90 transition-all duration-500' src='/image/header/miniHook.svg' />)}</motion.h6></Link>

                                    {
                                      item.dropDown &&
                                      <ul className='h-0 group-hover/innerDropDown:h-auto bg-transparent  overflow-hidden transition-all duration-500  '>
                                        {
                                          item.dropDown.map((item, index) => {
                                            return (
                                              <Link to={item.link}><li key={index} className='hover:text-tedakblue'>
                                                <motion.h6  className='  pl-5 '> {item.title}</motion.h6>
                                              </li>
                                              </Link>
                                            )
                                          })
                                        }
                                      </ul>
                                    }

                                  </li>
                                )
                              })
                            }
                          </ul>
                        }
                      </li>
                    )
                  })
                }
              </ul>
            </nav>


            <div className='   hidden lg:grid'>
                    <motion.div  className='text-black col-span-1 col-span-1 flex items-center justify-end space-x-2 text-10 '>
                        <h6 className='rounded-full p-1 border'>TR</h6>
                        <h6 className='p-1'>EN</h6>
                    </motion.div>

                </div>
            </div>
          </div>
        </div>










        {toggle &&
          <nav className={`bg-white absolute top-0 left-0 h-screen overflow-auto w-full bg-redloryColor mt-[5.5rem]  -z-10 pt-24 px-5 transform  transition-all duration-500 ${toggle ? 'opacity-100' : 'opacity-0'}`}>

            <div className='h-screen absolute top-0 left-0 overflow-hidden z-20'></div>
            <ul className='w-full z-50 relative  '>
              {
                DATA.map((item, index) => {
                  return (
                    <li key={index} className='w-full group'>

                      <div className='flex items-center h-16 '>
                        <Link className='' to={item.link} onClick={(e) => item.link ? '' : e.preventDefault()} >
                          <motion.h6 className={' w-24 text-black text-xl'}>{item.title}</motion.h6>
                        </Link>
                        <div className='w-full '>
                          {item.dropDown && (<div className='w-full block '></div>)}
                        </div>
                      </div>
                      {
                        item.dropDown &&
                        <ul className=' h-0 opacity-0 group-hover:opacity-100 group-hover:h-auto overflow-hidden transition-all duration-500 pl-[10%] '>
                          {
                            item.dropDown.map((item, index) => {
                              return (
                                <li key={index} className='group/innerDropDown py-1'>
                                  <div className='flex items-center '>
                                    <Link to={item.link} className=''>
                                      <motion.h6 className={'text-nowrap text-black text-base'}>{item.title}</motion.h6>
                                    </Link>
                                    <div className='w-full '>
                                      {item.dropDown && (<div className='w-full block ml-4'><motion.img src='/image/header/miniHook.svg' /></div>)}
                                    </div>
                                  </div>

                                  {
                                    item.dropDown &&
                                    <ul className='h-0 group-hover/innerDropDown:h-auto overflow-hidden transition-all duration-500 opacity-0 group-hover/innerDropDown:opacity-100 pl-[20%]'>
                                      {
                                        item.dropDown.map((item, index) => {
                                          return (
                                            <Link to={item.link}><li key={index} className='space-y-1'>
                                              <motion.h6 className='text-white text-sm' > {item.title}</motion.h6>
                                            </li>
                                            </Link>
                                          )
                                        })
                                      }
                                    </ul>
                                  }

                                </li>
                              )
                            })
                          }
                        </ul>
                      }
                    </li>
                  )
                })
              }
            </ul>
          </nav>
        }


      </motion.div>

    </header>

    </div>
  )
}

export default Header