// components/ProjelerimizGrid.js
import React, { useState } from 'react';
import { Link, useOutletContext } from 'react-router-dom';
import { motion } from 'framer-motion';
import Skeleton from './Skeleton';

const ProjelerimizGrid = () => {
  const { projectData } = useOutletContext();
  const [loadedImages, setLoadedImages] = useState({});

  const handleImageLoad = (index) => {
    setLoadedImages((prevState) => ({ ...prevState, [index]: true }));
  };

  const list = {
    visible: {
      opacity: 1,
      transition: {
        when: 'beforeChildren',
        staggerChildren: 0.3,
      },
    },
    hidden: { opacity: 0 },
  };
  const itemText = {
    visible: { opacity: 1, transition: { duration: 1 } },
    hidden: { opacity: 0 },
  };

  return (
    <motion.div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-5">
      {projectData &&
        projectData.data.map((item, index) => (
          <Link
            to={item.link}
            key={item.link}
            state={{ data: item.detailData }}
            className="group hover:scale-105 hover:shadow-lg transition-all duration-500"
          >
            <motion.div
              whileInView={{ opacity: 1, transition: { duration: 1 } }}
              initial={{ opacity: 0 }}
              className="h-full flex flex-col justify-between overflow-hidden"
            >
              <div className="p-5 bg-inancRed h-full">
                <h5 className="text-xs font-bold text-white">{item.title}</h5>
              </div>
              <div className="">
                {!loadedImages[index] && <Skeleton />}
                <motion.img
                  className={`group-hover:scale-105 transition-all duration-500 w-full h-72 object-cover ${!loadedImages[index] ? 'hidden' : ''}`}
                  src={item.img}
                  onLoad={() => handleImageLoad(index)}
                  style={{ display: loadedImages[index] ? 'block' : 'none' }}
                />
                <div className="bg-[#E9E9E9] text-white h-10">
                  <div className="inline-block">
                    <h5 className="!block text-16 mb-1 text-[#3c3c43]">Proje Detayı</h5>
                    <div className="h-[0.5px] group-hover:w-full transition-all duration-500 w-full bg-[#3c3c43]"></div>
                  </div>
                </div>
              </div>
            </motion.div>
          </Link>
        ))}
    </motion.div>
  );
};

export default ProjelerimizGrid;
