import React from 'react'
import Slider from '../components/Home/Slider'
import Slider2 from '../components/Home/Slider2'
import AboutUs from '../components/Home/AboutUs'
import Footer from '../components/Footer'
import HomeHeader from '../components/Home/HomeHeader'

const Home = () => {
  return (
    <>
      <HomeHeader />
      <Slider />
      <Slider2 />
      <AboutUs />
    </>
  )
}

export default Home