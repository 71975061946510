import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { motion, useTransform, useViewportScroll } from "framer-motion"
import DATA from '../../data/header-data';
const HomeHeader = () => {


    const [toggle, setToggle] = useState(false)
    const location = useLocation();
    const { scrollY } = useViewportScroll();

    useEffect(() => {
        setToggle(false)
    }, [location])

    const bgColor = useTransform(
        scrollY,
        [0, 2 * 16],
        ["rgba(0, 0, 0, 0)", "rgba(255, 255, 255, 1)"]
    );

    const fontSizez = useTransform(
        scrollY,
        [0, 2 * 16],
        ["16px", "14px"]
    );


    const textColor = useTransform(
        scrollY,
        [0, 2 * 16],
        ["rgba(255, 255, 255, 1)", "rgba(0, 0, 0, 1)"]
    );

    const textColorReverse = useTransform(
        scrollY,
        [0, 2 * 16],
        ["rgba(0, 0, 0, 1)", "rgba(255, 255, 255, 1)"]
    );

    const toggleMenuIconColor = useTransform(
        scrollY,
        [0, 2 * 16],
        ["rgba(255, 255, 255, 1)", "rgba(0, 154, 162, 1)"]
    );



    const iconFilterInvert = useTransform(
        scrollY,
        [0, 2 * 16],
        [1, 0]
    );


    const logoVisibility= useTransform(
        scrollY,
        [0, 2 * 16],
        [0, 1]
    );

    const logoVisibilityReverse= useTransform(
        scrollY,
        [0, 2 * 16],
        [1, 0]
    );


    const bgInancRed= useTransform(
        scrollY,
        [0, 2 * 16],
        ['rgb(152,0,46)', 'rgba(255, 255, 255)']
    );


    return (
        <div className='fixed left-0  w-full z-40 '>
            <motion.div style={{background:bgColor}} className='grid grid-cols-12 lg:grid-cols-none h-[5.5rem] content-center'>
                <div className='col-start-2 col-end-12 lg:col-start-auto lg:col-end-auto grid-cols-2 grid lg:grid-cols-3'>
                <div className='hidden lg:block'></div>
                <div className='flex items-center lg:justify-center relative'>
                    <motion.img style={{opacity:logoVisibilityReverse}} className='h-12  absolute left-0 lg:left-auto' src='/images/inanc-white-logo.svg' />
                    <motion.img style={{opacity:logoVisibility}} className='h-12  absolute left-0 lg:left-auto' src='/images/inanc-black-logo.svg' />
                </div>
                <div className='  grid-cols-2 hidden lg:grid'>
                    <motion.div style={{color:textColor}} className='col-span-1 col-span-1 flex items-center justify-end space-x-2 text-10 text-white'>
                        <h6 className='rounded-full p-1 border'>TR</h6>
                        <h6 className='p-1'>EN</h6>
                    </motion.div>

                </div>
                <div onClick={() => setToggle(!toggle)} className='lg:hidden flex w-full justify-end items-center'>
                    <div className='h-5 w-8  flex items-center justify-center relative'>
                        <motion.div style={{background:textColor}} className={`w-8 h-0.5   absolute top-0 transition-all duration-500 ${!toggle ? 'top-0' : 'top-2.5'}  ${!toggle ? 'rotate-0' : 'rotate-45'}`} ></motion.div>
                        <motion.div style={{background:textColor}} className={`w-8 h-0.5  ${!toggle ? 'top-0' : 'top-2.5'} absolute transition-all duration-500  ${!toggle ? 'top-2.5' : 'top-2.5'} ${!toggle ? 'translate-x-0' : 'translate-x-96'}`}></motion.div>
                        <motion.div style={{background:textColor}} className={`w-8 h-0.5 ${!toggle ? 'top-0' : 'top-2.5'} absolute bottom-0 transition-all duration-500  ${!toggle ? 'top-5' : 'top-2.5'} ${!toggle ? 'rotate-0' : '-rotate-45'}`}></motion.div>

                    </div>
                </div>
                </div>
            </motion.div>
            <div className=' w-full border-t border-t-[rgba(196,196,196)]  '>

                <header className="flex justify-center   fixed w-full   left-0 z-[100] ">
                    <motion.div  className="w-full  3xl:container  fixed  flex items-center p-0   ">

                        <motion.div style={{background:bgColor}} className='grid grid-cols-3 w-full '>
                            <div className='px-5 lg:px-0 col-span-1  lg:col-start-2  lg:col-end-2   '>
                                <div className=' '>








                                    <nav className="lg:flex items-center  hidden col-span-2 justify-center h-[2.5rem] ">

                                        <ul className='flex  h-full'>
                                            {
                                                DATA.map((item, index) => {
                                                    return (
                                                        <li key={index} className='group cursor-pointer relative flex  items-center  px-4 transition-all duration-500 '>
                                                            <Link to={item.link}><motion.h6 style={{color:textColor}}  className={`${item.link === location.pathname ? '!text-[#C4C4C4]' : 'text-white'} flex  group-hover:!text-tedakblue !text-14 openSans font-semibold hover:!text-[#FF7676] transition-all duration-500`}>{item.title}
                                                            </motion.h6></Link>
                                                            {
                                                                item.dropDown &&
                                                                <ul className='absolute opacity-0 top-[2.7rem] left-0 invisible group-hover:visible  bg-white  group-hover:opacity-100  overflow-hidden   transition-all duration-500 w-max shadow-sm'>
                                                                    {
                                                                        item.dropDown.map((item, index) => {
                                                                            return (
                                                                                <li key={index} className='group/innerDropDown '>
                                                                                    <Link to={item.link}><motion.h6 className={` px-4 py-2 flex group-hover/innerDropDown:!text-tedakblue !text-14 openSans font-semibold hover:!bg-customGray2`}> {item.title}{item.dropDown && (<img className='ml-2 transform group-hover/innerDropDown:rotate-90 transition-all duration-500' src='/image/header/miniHook.svg' />)}</motion.h6></Link>

                                                                                    {
                                                                                        item.dropDown &&
                                                                                        <ul className='h-0 group-hover/innerDropDown:h-auto bg-transparent  overflow-hidden transition-all duration-500  '>
                                                                                            {
                                                                                                item.dropDown.map((item, index) => {
                                                                                                    return (
                                                                                                        <Link to={item.link}><li key={index} className='hover:text-tedakblue'>
                                                                                                            <motion.h6 style={{ fontSize: fontSizez }} className='  pl-5 '> {item.title}</motion.h6>
                                                                                                        </li>
                                                                                                        </Link>
                                                                                                    )
                                                                                                })
                                                                                            }
                                                                                        </ul>
                                                                                    }

                                                                                </li>
                                                                            )
                                                                        })
                                                                    }
                                                                </ul>
                                                            }
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    </nav>



                                </div>
                            </div>
                        </motion.div>










                        {toggle &&
                            <motion.nav style={{background:bgInancRed}} className={`absolute top-0 left-0 h-screen overflow-auto w-full    -z-10 pt-24 px-5 transform  transition-all duration-500 ${toggle ? 'opacity-100' : 'opacity-0'}`}>

                                <div className='h-screen absolute top-0 left-0 overflow-hidden z-20'></div>
                                <ul className='w-full z-50 relative  '>
                                    {
                                        DATA.map((item, index) => {
                                            return (
                                                <li key={index} className='w-full group'>

                                                    <div className='flex items-center h-16 '>
                                                        <Link className='' to={item.link} onClick={(e) => item.link ? '' : e.preventDefault()} >
                                                            <motion.h6 style={{color:textColor}} className={' w-24 text-white text-xl'}>{item.title}</motion.h6>
                                                        </Link>
                                                        <div className='w-full '>
                                                            {item.dropDown && (<div className='w-full block '></div>)}
                                                        </div>
                                                    </div>
                                                    {
                                                        item.dropDown &&
                                                        <ul className=' h-0 opacity-0 group-hover:opacity-100 group-hover:h-auto overflow-hidden transition-all duration-500 pl-[10%] '>
                                                            {
                                                                item.dropDown.map((item, index) => {
                                                                    return (
                                                                        <li key={index} className='group/innerDropDown py-1'>
                                                                            <div className='flex items-center '>
                                                                                <Link to={item.link} className=''>
                                                                                    <motion.h6 style={{color:textColor}} className={'text-nowrap text-white text-base'}>{item.title}</motion.h6>
                                                                                </Link>
                                                                                <div className='w-full '>
                                                                                    {item.dropDown && (<div className='w-full block ml-4'><motion.img src='/image/header/miniHook.svg' /></div>)}
                                                                                </div>
                                                                            </div>

                                                                            {
                                                                                item.dropDown &&
                                                                                <ul className='h-0 group-hover/innerDropDown:h-auto overflow-hidden transition-all duration-500 opacity-0 group-hover/innerDropDown:opacity-100 pl-[20%]'>
                                                                                    {
                                                                                        item.dropDown.map((item, index) => {
                                                                                            return (
                                                                                                <Link to={item.link}><li key={index} className='space-y-1'>
                                                                                                    <motion.h6 className='text-white text-sm' > {item.title}</motion.h6>
                                                                                                </li>
                                                                                                </Link>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </ul>
                                                                            }

                                                                        </li>
                                                                    )
                                                                })
                                                            }
                                                        </ul>
                                                    }
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </motion.nav>
                        }


                    </motion.div>

                </header>

            </div>



        </div>
    )
}

export default HomeHeader