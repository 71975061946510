import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { motion } from "framer-motion"

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';



// import required modules
import { Autoplay, Pagination } from 'swiper/modules';
import { Link } from 'react-router-dom';

export default function Slider2() {



const list = {
  visible: {
      opacity: 1,

      transition: {
          when: "beforeChildren",
          staggerChildren: 0.3,
          
      },
  },
  hidden: { opacity: 0 },

}

const itemText = { visible: { opacity: 1, transition: { duration: 1 } }, hidden: { opacity: 0 }, }
  return (
    <div >
       <div className='h-20 bg-[#E9E9E9]'></div>
    <motion.div initial="hidden" whileInView="visible" variants={list} className='bg-[#E9E9E9] pb-20'>
      <div className='grid grid-cols-12'>
        <div className='col-start-2 col-end-12 md:col-start-4  md:col-end-10  pb-10'>
          <motion.h5  variants={itemText}  className='md:text-center font-bold text-55 mb-5'>Projelerimiz</motion.h5>
          <motion.p variants={itemText} className='text-start md:text-center text-18 text-customGray'>Projelerimiz, sektördeki en güncel bilgi ve teknolojiyi kullanarak yenilikçi çözümler sunuyor. Her
            <br className='hidden md:block'/>
            bir proje, başından sonuna kadar titiz bir planlama ve uzmanlık gerektirir. Müşteri ihtiyaçlarını
            <br  className='hidden md:block'/>
            anlamak ve en iyi sonuçları elde etmek için sürekli araştırma ve geliştirme yapıyoruz.</motion.p>
        </div>
      </div>
      <div className=' relative grid grid-cols-12'>
        <motion.div variants={itemText} className="col-start-2 col-end-12 md:col-start-3 md:col-end-11">
          <Swiper
            slidesPerView={2}
            autoplay={{
              delay: 3000, // 3000ms = 3 seconds
              disableOnInteraction: false, // User interaction won't stop autoplay
            }}
            spaceBetween={30}
            pagination={{
              clickable: true,
            }}
            

            breakpoints={{
              // when window width is >= 320px
              320: {
                slidesPerView: 1,
                spaceBetween: 10,
              },
              // when window width is >= 640px
              640: {
                slidesPerView: 2,
                spaceBetween: 30,
              },
            }}
            modules={[Pagination, Autoplay]}
            className="h-[58vh]"
          >
            <SwiperSlide><img className='h-[45vh] object-cover' src='/images/project/1.jpg' /></SwiperSlide>
            <SwiperSlide ><img className='h-[45vh] object-cover' src='/images/project/2.jpg' /></SwiperSlide>
            <SwiperSlide><img className='h-[45vh] object-cover' src='/images/project/1.jpg' /></SwiperSlide>
            <SwiperSlide><img className='h-[45vh] object-cover' src='/images/project/2.jpg' /></SwiperSlide>
            <SwiperSlide><img className='h-[45vh] object-cover' src='/images/project/1.jpg' /></SwiperSlide>
            <SwiperSlide><img className='h-[45vh] object-cover' src='/images/project/2.jpg' /></SwiperSlide>
            <SwiperSlide><img className='h-[45vh] object-cover' src='/images/project/1.jpg' /></SwiperSlide>
            <SwiperSlide><img className='h-[45vh] object-cover' src='/images/project/2.jpg' /></SwiperSlide>
          </Swiper>
        </motion.div>
      </div>
      <motion.div  variants={itemText} >
      <Link  to={'/projelerimiz/tamamlanan-projeler'} className='w-full flex justify-center items-center  pt-8 transition-all duration-500 group hover:scale-105'>
        <div className=' py-2 px-8 border-[1.5px] border-customGray group-hover:bg-inancRed group-hover:text-white bg-white text-14 rounded-2xl text-customGray transition-all duration-500'>DAHA FAZLASI İÇİN</div>
      </Link>
      </motion.div>
    </motion.div>
    </div>
  );
}
