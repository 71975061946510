// components/Skeleton.js
import React from 'react';
import { motion } from 'framer-motion';

const skeletonVariants = {
  initial: { opacity: 0 },
  animate: {
    opacity: [0.4, 0.8, 0.4],
    
    transition: {
      duration: 1.5,
      repeat: Infinity,
      repeatType: 'reverse',
    },
  },
};

const Skeleton = () => {
  return (
    <motion.div
      className="w-full h-72 bg-inancRed"
      variants={skeletonVariants}
      initial="initial"
      animate="animate"
    ></motion.div>
  );
};

export default Skeleton;
