import React from 'react'
import { motion } from "framer-motion"
const Iletisim = () => {
  const list = {
    visible: {
        opacity: 1,
  
        transition: {
            when: "beforeChildren",
            staggerChildren: 0.3,
            
        },
    },
    hidden: { opacity: 0 },
  
  }
  
  const itemText = { visible: { opacity: 1, transition: { duration: 1 } }, hidden: { opacity: 0 }, }
  return (
    <motion.div  initial="hidden" whileInView="visible" variants={list} className='  grid grid-cols-12 pt-24'>
      <div className='col-start-2 col-end-13 md:col-start-3 md:col-end-6  mt-20 mb-20'>
          <div className=' '>
            <h5 className='font-semibold text-35 mb-10'>İletişim</h5>
            <div className='flex flex-col space-y-10'>
                <motion.div variants={itemText} className='flex space-x-5'>
                    <img src='/images/contact/1.svg'/>
                    <div className='flex flex-col justify-center'>
                        <a  target='_blank' href='tel:+902582634658' className='inline-block font-light text-customBlack'>+90 258 263 46 58</a>
                    </div>
                </motion.div>
                <motion.div variants={itemText} className='flex space-x-5'>
                    <img src='/images/contact/2.svg'/>
                    <div className='flex flex-col justify-center space-y-1'>
                        <a  target='_blank' href='mailto:info@inancinsaat.com' className='inline-block font-light text-customBlack'>info@inancinsaat.com</a>
                        {/* <div className='flex space-x-4'>
                          <a target='_blank' href='#' className='inline-block '><img src='/images/contact/f.svg'/></a>
                          <a target='_blank' href='#' className='inline-block '><img src='/images/contact/i.svg'/></a>
                          <a target='_blank' href='#' className='inline-block '><img src='/images/contact/l.svg'/></a>
                        </div> */}
                    </div>
                </motion.div>
                <motion.div variants={itemText} className='flex space-x-5 '>
                    <img src='/images/contact/3.svg'/>
                    <div className='flex flex-col justify-center'>
                        <a target='_blank' href='https://maps.app.goo.gl/VmNLz57CH1eRpMwz5' className='inline-block font-light text-customBlack'>Sırakapılar Mah. 1526 Sok.No: 2 D: 3
                        Pamukkle / DENİZLİ</a>
                    </div>
                </motion.div>

            </div>
          </div>
      </div>
      <div className='col-start-2 col-end-12 md:col-start-6 md:col-end-13  mt-20 mb-20'>
          <div className='pl-0 md:pl-20'>
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3153.4879327924805!2d29.0820571!3d37.7786034!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14c73faf941342c9%3A0xcce896a07271e07!2zSW5hbsOnIEluxZ9hYXQgJiBNw7xoZW5kaXNsaWs!5e0!3m2!1sen!2str!4v1720267187658!5m2!1sen!2str" width="100%" height="450"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </div>
      </div>

    </motion.div>
  )
}

export default Iletisim