// import logo from './logo.svg';
// import './App.css';

import { AnimatePresence } from "framer-motion";
import Footer from "./components/Footer";
import AboutUs from "./components/Home/AboutUs";
import Slider from "./components/Home/Slider";
import Slider2 from "./components/Home/Slider2";
import { Route, Routes, useLocation } from "react-router-dom";
import PageContainer from "./container/PageContainer";
import { useEffect } from "react";
import Home from "./pages/Home";
import Hakkimizda from "./pages/Hakkimizda";
import Projelerimiz from "./pages/Projelerimiz";
import Iletisim from "./pages/Iletisim";
import ProjelerimizGrid from "./components/ProjelerimizGrid";
import ProjelerimizDetay from "./components/Projelerimiz/ProjelerimizDetay";

function App() {
  const location = useLocation();



  useEffect(() => {

      window.scrollTo(0, 0);

  }, [location]);
  return (
  
          <div className="App relative 3xl:container mx-auto overflow-x-hidden">
      <AnimatePresence mode="wait">
        <Routes location={location} key={location.pathname}>
          <Route path="/" element={<PageContainer />}>
              <Route  path="/" element={<Home/>}/>
              <Route  path="hakkimizda" element={<Hakkimizda/>}/>
              <Route  path="projelerimiz" element={<Projelerimiz/>}>
                  <Route path=":id" element={<ProjelerimizGrid/>}/>
              </Route>
              <Route  path="projelerimiz/:id" >
                  <Route path=":id" element={<ProjelerimizDetay/>}/>
              </Route>


              <Route  path="iletisim" element={<Iletisim/>}/>
          </Route>
        </Routes>
      </AnimatePresence>
    </div>

  );
}

export default App;
