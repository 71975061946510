const DATA =
  [
    {
      type: 'tamamlanan-projeler',
      data: [
        {
          title: 'PAÜ ÇEVRE DÜZENLEME VE ALTYAPI İŞLERİ',
          img: '/images/project/pau-cevre-duzenleme-ve-altyapi-isleri-1/main.jpg',
          subTitle: 'İNANÇ İnş. Taah. Nak. Mim. Müh. San. ve Tic. Ltd. Şti. Taahhüdünde Tamamlanmıştır.',
          link: 'pau-cevre-duzenleme-ve-altyapi-isleri-1',
          detailData: {
            img: '/images/project/pau-cevre-duzenleme-ve-altyapi-isleri-1/main.jpg',
            year: '2000',
            data: [
              '/images/project/pau-cevre-duzenleme-ve-altyapi-isleri-1/main.jpg',
              '/images/project/pau-cevre-duzenleme-ve-altyapi-isleri-1/1.jpg',
              '/images/project/pau-cevre-duzenleme-ve-altyapi-isleri-1/2.jpg',


            ]
          }
        },
        {
          title: 'PAÜ ÇEVRE DÜZENLEME VE ALTYAPI İŞLERİ',
          img: '/images/project/pau-cevre-duzenleme-ve-altyapi-isleri-2/base.jpg',
          subTitle: 'İNANÇ İnş. Taah. Nak. Mim. Müh. San. ve Tic. Ltd. Şti. Taahhüdünde Tamamlanmıştır.',
          link: 'pau-cevre-duzenleme-ve-altyapi-isleri-2',
          detailData: {
            img: '/images/project/pau-cevre-duzenleme-ve-altyapi-isleri-2/main.jpg',
            year: '2000',
            data: [
              '/images/project/pau-cevre-duzenleme-ve-altyapi-isleri-2/main.jpg',
              '/images/project/pau-cevre-duzenleme-ve-altyapi-isleri-2/1.jpg',
              '/images/project/pau-cevre-duzenleme-ve-altyapi-isleri-2/2.jpg',


            ]
          }
        },
        {
          title: 'PAÜ ÇEVRE DÜZENLEME VE ALTYAPI İŞLERİ',
          img: '/images/project/pau-cevre-duzenleme-ve-altyapi-isleri-3/main.jpg',
          subTitle: 'İNANÇ İnş. Taah. Nak. Mim. Müh. San. ve Tic. Ltd. Şti. Taahhüdünde Tamamlanmıştır.',
          link: 'pau-cevre-duzenleme-ve-altyapi-isleri-3',
          detailData: {
            img: '/images/project/pau-cevre-duzenleme-ve-altyapi-isleri-3/main.jpg',
            year: '2000',
            data: [
              '/images/project/pau-cevre-duzenleme-ve-altyapi-isleri-3/main.jpg',
              '/images/project/pau-cevre-duzenleme-ve-altyapi-isleri-3/1.jpg',
              '/images/project/pau-cevre-duzenleme-ve-altyapi-isleri-3/2.jpg',
 


            ]
          }
        },
        {
          title: 'PAÜ ÇEVRE DÜZENLEME VE ALTYAPI İŞLERİ',
          img: '/images/project/pau-cevre-duzenleme-ve-altyapi-isleri-4/main.jpg',
          subTitle: 'İNANÇ İnş. Taah. Nak. Mim. Müh. San. ve Tic. Ltd. Şti. Taahhüdünde Tamamlanmıştır.',
          link: 'pau-cevre-duzenleme-ve-altyapi-isleri-4',
          detailData: {
            img: '/images/project/pau-cevre-duzenleme-ve-altyapi-isleri-4/main.jpg',
            year: '2000',
            data: [
              '/images/project/pau-cevre-duzenleme-ve-altyapi-isleri-4/main.jpg',
 


            ]
          }
        },
        {
          title: 'PAÜ HEMATOLOJİ VE ONKOLOJİ MERKEZİ',
          img: '/images/project/pau-hematoloji-ve-onkoloji-merkezi/main.jpg',
          subTitle: 'İNANÇ İnş. Taah. Nak. Mim. Müh. San. ve Tic. Ltd. Şti. Taahhüdünde Tamamlanmıştır.',
          link: 'pau-hematoloji-ve-onkoloji-merkezi',
          detailData: {
            img: '/images/project/pau-hematoloji-ve-onkoloji-merkezi/main.jpg',
            year: '2000',
            data: [
              '/images/project/pau-hematoloji-ve-onkoloji-merkezi/main.jpg',
              '/images/project/pau-hematoloji-ve-onkoloji-merkezi/1.jpg',
              '/images/project/pau-hematoloji-ve-onkoloji-merkezi/2.jpg',



            ]
          }
        },
        {
          title: 'TEKNOLOJİ FAKÜLTESİ',
          img: '/images/project/teknoloji-fakultesi/main.jpg',
          subTitle: 'İNANÇ İnş. Taah. Nak. Mim. Müh. San. ve Tic. Ltd. Şti. Taahhüdünde Tamamlanmıştır.',
          link: 'teknoloji-fakultesi',
          detailData: {
            img: '/images/project/teknoloji-fakultesi/main.jpg',
            year: '2000',
            data: [
              '/images/project/teknoloji-fakultesi/main.jpg',
              '/images/project/teknoloji-fakultesi/1.jpg',
              '/images/project/teknoloji-fakultesi/2.jpg',



            ]
          }
        },
        {
          title: 'DENİZLİ SANAYİ ODASI HİZMET BİNASI VE KAPALI OTOPARK',
          img: '/images/project/denizli-sanayi-odasi-hizmet-binasi-ve-kapali-otopark/main.jpg',
          subTitle: 'İNANÇ İnş. Taah. Nak. Mim. Müh. San. ve Tic. Ltd. Şti. Taahhüdünde Tamamlanmıştır.',
          link: 'denizli-sanayi-odasi-hizmet-binasi-ve-kapali-otopark',
          detailData: {
            img: '/images/project/denizli-sanayi-odasi-hizmet-binasi-ve-kapali-otopark/main.jpg',
            year: '2000',
            data: [
              '/images/project/denizli-sanayi-odasi-hizmet-binasi-ve-kapali-otopark/main.jpg',
              '/images/project/denizli-sanayi-odasi-hizmet-binasi-ve-kapali-otopark/1.jpg',
              '/images/project/denizli-sanayi-odasi-hizmet-binasi-ve-kapali-otopark/2.jpg',


            ]
          }
        },


        {
          title: 'DENİZLİ BÜYÜKŞEHİR BELEDİYESİ KARAHASANLI YÜZME HAVUZU YAPIM İŞİ',
          img: '/images/project/denizli-buyuksehir-belediyesi-karahasanli-yuzme-havuzu-yapim-isi/base.jpg',
          subTitle: 'İNANÇ İnş. Taah. Nak. Mim. Müh. San. ve Tic. Ltd. Şti. Taahhüdünde Tamamlanmıştır.',
          link: 'denizli-buyuksehir-belediyesi-karahasanli-yuzme-havuzu-yapim-isi',
          detailData: {
            img: '/images/project/denizli-buyuksehir-belediyesi-karahasanli-yuzme-havuzu-yapim-isi/main.jpg',
            year: '2000',
            data: [
              '/images/project/denizli-buyuksehir-belediyesi-karahasanli-yuzme-havuzu-yapim-isi/main.jpg',
              '/images/project/denizli-buyuksehir-belediyesi-karahasanli-yuzme-havuzu-yapim-isi/1.jpg',
              '/images/project/denizli-buyuksehir-belediyesi-karahasanli-yuzme-havuzu-yapim-isi/2.jpg',


            ]
          }
        },




        {
          title: 'DENİZLİ MERKEZEFENDİ BEREKETLER TOPLANTI VE SERGİ SALONU',
          img: '/images/project/denizli-merkezefendi-bereketler-toplanti-ve-sergi-salonu/main.jpg',
          subTitle: 'İNANÇ İnş. Taah. Nak. Mim. Müh. San. ve Tic. Ltd. Şti. Taahhüdünde Tamamlanmıştır.',
          link: 'denizli-merkezefendi-bereketler-toplanti-ve-sergi-salonu',
          detailData: {
            img: '/images/project/denizli-merkezefendi-bereketler-toplanti-ve-sergi-salonu/main.jpg',
            year: '2000',
            data: [
              '/images/project/denizli-merkezefendi-bereketler-toplanti-ve-sergi-salonu/main.jpg',
'/images/project/denizli-merkezefendi-bereketler-toplanti-ve-sergi-salonu/1.jpg',
// '/images/project/denizli-merkezefendi-bereketler-toplanti-ve-sergi-salonu/2.jpg',


            ]
          }
        },





        {
          title: 'DENİZLİ BÜYÜKŞEHİR BELEDİYESİ ÇAKMAK 5.000 M³ SU DEPOSU VE İSALE HATTI',
          img: '/images/project/denizli-buyuksehir-belediyesi-cakmak-5000-m3-su-deposu-ve-isale-hati/main.jpg',
          subTitle: 'İNANÇ İnş. Taah. Nak. Mim. Müh. San. ve Tic. Ltd. Şti. Taahhüdünde Tamamlanmıştır.',
          link: 'denizli-buyuksehir-belediyesi-cakmak-5000-m3-su-deposu-ve-isale-hati',
          detailData: {
            img: '/images/project/denizli-buyuksehir-belediyesi-cakmak-5000-m3-su-deposu-ve-isale-hati/main.jpg',
            year: '2000',
            data: [
              '/images/project/denizli-buyuksehir-belediyesi-cakmak-5000-m3-su-deposu-ve-isale-hati/main.jpg',
'/images/project/denizli-buyuksehir-belediyesi-cakmak-5000-m3-su-deposu-ve-isale-hati/1.jpg',
'/images/project/denizli-buyuksehir-belediyesi-cakmak-5000-m3-su-deposu-ve-isale-hati/2.jpg',


            ]
          }
        },





        {
          title: 'PAÜ DİŞ HEKİMLİĞİ FAKÜLTESİ',
          img: '/images/project/pau-dis-hekimligi-fakultesi/main.jpg',
          subTitle: 'İNANÇ İnş. Taah. Nak. Mim. Müh. San. ve Tic. Ltd. Şti. Taahhüdünde Tamamlanmıştır.',
          link: 'pau-dis-hekimligi-fakultesi',
          detailData: {
            img: '/images/project/pau-dis-hekimligi-fakultesi/main.jpg',
            year: '2000',
            data: [
              '/images/project/pau-dis-hekimligi-fakultesi/main.jpg',
'/images/project/pau-dis-hekimligi-fakultesi/1.jpg',
'/images/project/pau-dis-hekimligi-fakultesi/2.jpg',


            ]
          }
        },

        {
          title: 'TIP FAKÜLTESİ DERSLİK BİNASI',
          img: '/images/project/tip-fakultesi-derslik-binasi/main.jpg',
          subTitle: 'İNANÇ İnş. Taah. Nak. Mim. Müh. San. ve Tic. Ltd. Şti. Taahhüdünde Tamamlanmıştır.',
          link: 'tip-fakultesi-derslik-binasi',
          detailData: {
            img: '/images/project/tip-fakultesi-derslik-binasi/main.jpg',
            year: '2000',
            data: [
              '/images/project/tip-fakultesi-derslik-binasi/main.jpg',
'/images/project/tip-fakultesi-derslik-binasi/1.jpg',
// '/images/project/tip-fakultesi-derslik-binasi/2.jpg',


            ]
          }
        },
        {
          title: 'DENİZLİ BEREKETLİ AKGÜN SİTESİ',
          img: '/images/project/denizli-bereketli-akgun-sitesi/main.jpg',
          subTitle: 'İNANÇ İnş. Taah. Nak. Mim. Müh. San. ve Tic. Ltd. Şti. Taahhüdünde Tamamlanmıştır.',
          link: 'denizli-bereketli-akgun-sitesi',
          detailData: {
            img: '/images/project/denizli-bereketli-akgun-sitesi/main.jpg',
            year: '2000',
            data: [
              '/images/project/denizli-bereketli-akgun-sitesi/main.jpg',


            ]
          }
        },





        {
          title: 'TAVAS YÜZME HAVUZU VE SPOR KOMPLEKSİ YAPIM İŞİ',
          img: '/images/project/tavas-yuzme-havuzu-ve-spor-kompleksi-yapim-isi/main.jpg',
          subTitle: 'İNANÇ İnş. Taah. Nak. Mim. Müh. San. ve Tic. Ltd. Şti. Taahhüdünde Tamamlanmıştır.',
          link: 'tavas-yuzme-havuzu-ve-spor-kompleksi-yapim-isi',
          detailData: {
            img: '/images/project/tavas-yuzme-havuzu-ve-spor-kompleksi-yapim-isi/main.jpg',
            year: '2000',
            data: [
'/images/project/tavas-yuzme-havuzu-ve-spor-kompleksi-yapim-isi/main.jpg',



            ]
          }
        },


        {
          title: 'PAMUKKALE İLÇESİ AKTEPE 24 DERSLİKLİ OKUL İNŞAATI',
          img: '/images/project/pamukkale-ilcesi-aktepe-24-derslikli-okul-insaati/main.jpg',
          subTitle: 'İNANÇ İnş. Taah. Nak. Mim. Müh. San. ve Tic. Ltd. Şti. Taahhüdünde Tamamlanmıştır.',
          link: 'pamukkale-ilcesi-aktepe-24-derslikli-okul-insaati',
          detailData: {
            img: '/images/project/pamukkale-ilcesi-aktepe-24-derslikli-okul-insaati/main.jpg',
            year: '2000',
            data: [
'/images/project/pamukkale-ilcesi-aktepe-24-derslikli-okul-insaati/main.jpg',




            ]
          }
        },



        {
          title: 'PAMUKKALE İLÇESİ PINARKENT MAHALLESİ 24 DERSLİKLİ ORTAOKULU',
          img: '/images/project/pamukkale-ilcesi-pinarkent-mahallesi-24-derslikli-ortaokulu/main.jpg',
          subTitle: 'İNANÇ İnş. Taah. Nak. Mim. Müh. San. ve Tic. Ltd. Şti. Taahhüdünde Tamamlanmıştır.',
          link: 'pamukkale-ilcesi-pinarkent-mahallesi-24-derslikli-ortaokulu',
          detailData: {
            img: '/images/project/pamukkale-ilcesi-pinarkent-mahallesi-24-derslikli-ortaokulu/main.jpg',
            year: '2000',
            data: [
'/images/project/pamukkale-ilcesi-pinarkent-mahallesi-24-derslikli-ortaokulu/main.jpg',




            ]
          }
        },




        {
          title: 'HONAZ BELEDİYESİ DÜĞÜN SALONU VE İŞYERLERİ',
          img: '/images/project/honaz-belediyesi-dugun-salonu-ve-isyerleri/main.jpg',
          subTitle: 'İNANÇ İnş. Taah. Nak. Mim. Müh. San. ve Tic. Ltd. Şti. Taahhüdünde Tamamlanmıştır.',
          link: 'honaz-belediyesi-dugun-salonu-ve-isyerleri',
          detailData: {
            img: '/images/project/honaz-belediyesi-dugun-salonu-ve-isyerleri/main.jpg',
            year: '2000',
            data: [
'/images/project/honaz-belediyesi-dugun-salonu-ve-isyerleri/main.jpg',




            ]
          }
        },



        {
          title: 'DENİZLİ BÜYÜKŞEHİR BELEDİYESİ KAYAK MERKEZİ',
          img: '/images/project/denizli-buyuksehir-belediyesi-kayak-merkezi/main.jpg',
          subTitle: 'İNANÇ İnş. Taah. Nak. Mim. Müh. San. ve Tic. Ltd. Şti. Taahhüdünde Tamamlanmıştır.',
          link: 'denizli-buyuksehir-belediyesi-kayak-merkezi',
          detailData: {
            img: '/images/project/denizli-buyuksehir-belediyesi-kayak-merkezi/main.jpg',
            year: '2000',
            data: [
'/images/project/denizli-buyuksehir-belediyesi-kayak-merkezi/main.jpg',
'/images/project/denizli-buyuksehir-belediyesi-kayak-merkezi/1.jpg',




            ]
          }
        },



        {
          title: 'DENİZLİ ÇARDAK, GÖLCÜK VE ACIPAYAM DODURGA MAHALLELERİ TARIMSAL SULAMA İŞLERİ',
          img: '/images/project/denizli-cardak-golcuk-ve-acipayam-dodurga-mahalleleri-tarimsal-sulama-isleri/main.jpg',
          subTitle: 'İNANÇ İnş. Taah. Nak. Mim. Müh. San. ve Tic. Ltd. Şti. Taahhüdünde Tamamlanmıştır.',
          link: 'denizli-cardak-golcuk-ve-acipayam-dodurga-mahalleleri-tarimsal-sulama-isleri',
          detailData: {
            img: '/images/project/denizli-cardak-golcuk-ve-acipayam-dodurga-mahalleleri-tarimsal-sulama-isleri/main.jpg',
            year: '2000',
            data: [
'/images/project/denizli-cardak-golcuk-ve-acipayam-dodurga-mahalleleri-tarimsal-sulama-isleri/main.jpg',




            ]
          }
        },


        {
          title: 'DENİZLİ SERİNHİSAR İMAM HATİP ORTAOKULU',
          img: '/images/project/denizli-serinhisar-imam-hatip-ortaokulu/main.jpg',
          subTitle: 'İNANÇ İnş. Taah. Nak. Mim. Müh. San. ve Tic. Ltd. Şti. Taahhüdünde Tamamlanmıştır.',
          link: 'denizli-serinhisar-imam-hatip-ortaokulu',
          detailData: {
            img: '/images/project/denizli-serinhisar-imam-hatip-ortaokulu/main.jpg',
            year: '2000',
            data: [
'/images/project/denizli-serinhisar-imam-hatip-ortaokulu/main.jpg',




            ]
          }
        },




        {
          title: 'DENİZLİ TSİ HİZMET BİNASI VE LABORATUVARI',
          img: '/images/project/denizli-tsi-hizmet-binasi-ve-laboratuvari/main.jpg',
          subTitle: 'İNANÇ İnş. Taah. Nak. Mim. Müh. San. ve Tic. Ltd. Şti. Taahhüdünde Tamamlanmıştır.',
          link: 'denizli-tsi-hizmet-binasi-ve-laboratuvari',
          detailData: {
            img: '/images/project/denizli-tsi-hizmet-binasi-ve-laboratuvari/main.jpg',
            year: '2000',
            data: [
'/images/project/denizli-tsi-hizmet-binasi-ve-laboratuvari/main.jpg',




            ]
          }
        },



        {
          title: 'MANİSA MURADİYE VİZYON KENT HAVUZLU 16 VİLLA',
          img: '/images/project/manisa-muradiye-vizyon-kent-havuzlu-16-villa/main.jpg',
          subTitle: 'İNANÇ İnş. Taah. Nak. Mim. Müh. San. ve Tic. Ltd. Şti. Taahhüdünde Tamamlanmıştır.',
          link: 'manisa-muradiye-vizyon-kent-havuzlu-16-villa',
          detailData: {
            img: '/images/project/manisa-muradiye-vizyon-kent-havuzlu-16-villa/main.jpg',
            year: '2000',
            data: [
'/images/project/manisa-muradiye-vizyon-kent-havuzlu-16-villa/main.jpg',
'/images/project/manisa-muradiye-vizyon-kent-havuzlu-16-villa/1.jpg',




            ]
          }
        },


        {
          title: 'TEKİRDAĞ BÜYÜKŞEHİR BELEDİİYESİ SARAY, KAPAKLI, ÇERKEZKÖY, ÇORLU, ERGENE, SSB YOL İNŞAATLARI',
          img: '/images/project/tekirdag-buyuksehir-belediyesi-yol-insaatlari/main.jpg',
          subTitle: 'İNANÇ İnş. Taah. Nak. Mim. Müh. San. ve Tic. Ltd. Şti. Taahhüdünde Tamamlanmıştır.',
          link: 'tekirdag-buyuksehir-belediyesi-yol-insaatlari',
          detailData: {
            img: '/images/project/tekirdag-buyuksehir-belediyesi-yol-insaatlari/main.jpg',
            year: '2000',
            data: [
'/images/project/tekirdag-buyuksehir-belediyesi-yol-insaatlari/main.jpg',
'/images/project/tekirdag-buyuksehir-belediyesi-yol-insaatlari/1.jpg',




            ]
          }
        },



        {
          title: 'DENİZLİ SERVERGAZİ HAYIRSEVERLER ORTAOKULU',
          img: '/images/project/denizli-servergazi-hayirseverler-ortaokulu/main.jpg',
          subTitle: 'İNANÇ İnş. Taah. Nak. Mim. Müh. San. ve Tic. Ltd. Şti. Taahhüdünde Tamamlanmıştır.',
          link: 'denizli-servergazi-hayirseverler-ortaokulu',
          detailData: {
            img: '/images/project/denizli-servergazi-hayirseverler-ortaokulu/main.jpg',
            year: '2000',
            data: [
'/images/project/denizli-servergazi-hayirseverler-ortaokulu/main.jpg',
            ]
          }
        },


        {
          title: 'DENİZLİ MERKEZEFENDİ GÜMÜŞÇAY MAH. 72 DAİRE KONUT İNŞAATI',
          img: '/images/project/denizli-merkezefendi-gumuscay-mah-72-daire-konut-insaati/main.jpg',
          subTitle: 'İNANÇ İnş. Taah. Nak. Mim. Müh. San. ve Tic. Ltd. Şti. Taahhüdünde Tamamlanmıştır.',
          link: 'denizli-merkezefendi-gumuscay-mah-72-daire-konut-insaati',
          detailData: {
            img: '/images/project/denizli-merkezefendi-gumuscay-mah-72-daire-konut-insaati/main.jpg',
            year: '2000',
            data: [
'/images/project/denizli-merkezefendi-gumuscay-mah-72-daire-konut-insaati/main.jpg',
            ]
          }
        },




        {
          title: 'DENİZLİ MERKEZEFENDİ BELEDİYESİ ENGELSİZ YAŞAM AKADEMİSİ',
          img: '/images/project/denizli-merkezefendi-belediyesi-engelsiz-yasam-akademisi/main.jpg',
          subTitle: 'İNANÇ İnş. Taah. Nak. Mim. Müh. San. ve Tic. Ltd. Şti. Taahhüdünde Tamamlanmıştır.',
          link: 'denizli-merkezefendi-belediyesi-engelsiz-yasam-akademisi',
          detailData: {
            img: '/images/project/denizli-merkezefendi-belediyesi-engelsiz-yasam-akademisi/main.jpg',
            year: '2000',
            data: [
'/images/project/denizli-merkezefendi-belediyesi-engelsiz-yasam-akademisi/main.jpg',
'/images/project/denizli-merkezefendi-belediyesi-engelsiz-yasam-akademisi/1.jpg',
            ]
          }
        },







      ]

    },
























    {
      type: 'devam-eden-projeler',
      data: [
        {
          title: 'DENİZLİ BÜYÜKŞEHİR BELEDİYESİ ENGELSİZ SPOR VE YAŞAM MERKEZİ',
          img: '/images/project/denizli-buyuksehir-belediyesi-engelsiz-spor-ve-yasam-merkezi/base.jpg',
          subTitle: 'İNANÇ İnş. Taah. Nak. Mim. Müh. San. ve Tic. Ltd. Şti. Taahhüdünde Tamamlanmıştır.',
          link: 'denizli-buyuksehir-belediyesi-engelsiz-spor-ve-yasam-merkezi',
          detailData: {
            img: '/images/project/denizli-buyuksehir-belediyesi-engelsiz-spor-ve-yasam-merkezi/main.jpg',
            year: '2000',
            data: [
              '/images/project/denizli-buyuksehir-belediyesi-engelsiz-spor-ve-yasam-merkezi/1.jpg',
              '/images/project/denizli-buyuksehir-belediyesi-engelsiz-spor-ve-yasam-merkezi/2.jpg',


            ]
          }
        },
        
      ]
    },
  ]


export default DATA;