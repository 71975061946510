import React, { useState } from 'react'

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules'
import { motion } from "framer-motion"
const Slider = () => {

  




  const [isAnimating, setIsAnimating] = useState(0);
  const scrollToCustomHeight = () => {
    const remInPixels = parseFloat(getComputedStyle(document.documentElement).fontSize);
    const additionalOffset = 3 * remInPixels;
    const scrollToPosition = window.innerHeight - additionalOffset;
    
    window.scrollTo({ top: scrollToPosition, behavior: 'smooth' });
  };


  return (
    <div className='h-screen'>
      <Swiper
        onSlideChange={(e) => setIsAnimating(e.activeIndex)}
        autoplay={{
          delay: 50000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,

        }}

        modules={[Autoplay, Pagination]}
        className=" "
      >
        <SwiperSlide className="relative h-screen bg-[#252541] overflow-hidden">
          <motion.img animate={{ scale: isAnimating === 0 ? [1.1, 1, 1.1] : 1, transition: { duration: 4, ease: 'easeIn', repeat: Infinity, repeatType: 'loop', } }} className='h-screen w-full object-cover' src='images/slider/slider1.jpg' />
          <div className="absolute h-screen w-full bg-[#98002E] z-20 top-0 left-0 mix-blend-multiply"></div>
          <div className='absolute h-full w-full top-0 left-0  z-20 flex justify-end  py-20  flex-col'>

            <div className='  flex items-center flex-col text-white'>
              <h4 className='text-65 font-bold text-center'>“Her Adımda Güven”</h4>
              <p className='text-center text-16'>İnşaat Alanında Sağlam Ve Güvenilir Çözümlerimizle Her Adımda Ileriye
                <br className='hidden md:block'/>
                Güvenle Adım Atın. Yenilikçi Yaklaşımımız Ve Kaliteli Hizmet Anlayışımızla
                <br className='hidden md:block'/>
                Projelerinizi Geleceğe Taşıyoruz.</p>
            </div>
            <div onClick={scrollToCustomHeight} className='w-full flex flex-col justify-center items-center py-10 cursor-pointer hover:scale-110  transition-all duration-500'>
      <motion.img 
        animate={{ 
          translateY: [0, 3, 0], 
           opacity: [1, 0.8, 1],
        }}
        transition={{ 
          duration: 0.5, 
          repeat: Infinity, 
          repeatDelay: 0.5 ,
          ease:'easeInOut'
        }}
        className='sli' 
        src='/images/slider-hook/slider-hook.svg'
      />
      <motion.img 
        animate={{ 
          translateY: [0, 3, 0], 
          opacity: [1, 0.8, 1],
        }}
        transition={{ 
          duration: 0.5, 
          repeat: Infinity, 
          repeatDelay: 0.5, 
          delay: 0.6 ,
                    ease:'easeInOut'
        }}
        className='sli' 
        src='/images/slider-hook/slider-hook.svg'
      />
    </div>

            {/* <motion.h4 animate={{ opacity: isAnimating === 0 ? [0, 1] : 0,translateX: isAnimating === 0 ? [10, 0] : 0, transition: { duration: 2, ease: 'easeIn' } }} initial={{ opacity: 0 ,translateX:0}}  className='text-63 font-bold  text-start md:text-end  openSans mb-10 w-full'>
            “Her Adımda Güven”
            </motion.h4> */}
            {/* <p>
            İnşaat Alanında Sağlam Ve Güvenilir Çözümlerimizle Her Adımda Ileriye Güvenle Adım Atın. Yenilikçi Yaklaşımımız Ve Kaliteli Hizmet Anlayışımızla Projelerinizi Geleceğe Taşıyoruz.
            </p> */}


          </div>

        </SwiperSlide>
        {/* 
        <SwiperSlide className="relative bg-[#252541]  overflow-hidden">
          <motion.img animate={{ scale: isAnimating === 1 ? [1.1, 1,1.1] : 1,  transition: { duration: 15, ease: 'easeIn',   repeat: Infinity,repeatType: 'loop', } }}   className='h-full w-full object-cover' src='images/swiper/5.jpg' />
          <div className='absolute h-full w-full top-0 left-0  z-10 grid grid-cols-10'>
          <div className='px-5 md:px-0 col-span-10 md:col-span-8 md:col-start-2  md:col-end-10    py-20 flex md:items-end md:justify-end flex-col text-white'>
            <motion.h4 animate={{ opacity: isAnimating === 1 ? [0, 1] : 0,translateX: isAnimating === 1 ? [10, 0] : 0, transition: { duration: 2, ease: 'easeIn' } }} initial={{ opacity: 0 ,translateX:0}}  className='text-63 font-bold  text-start md:text-end  openSans mb-10 w-full'>
            Sektördeki En İleri
            <br/>
            Kompozit Çözümler
            </motion.h4>
            <motion.p animate={{ opacity: isAnimating === 1 ? [0, 1] : 0, transition: { duration: 2, ease: 'easeIn' ,delay:1} }} initial={{ opacity: 0 }}  className='text-16 text-start md:text-end  font-bold  openSans w-full'>
            Redlory Kompozit, Sektördeki En Ileri Çözümleri 
            <br/>
            Sunuyor. İnovasyon Ve Kaliteyi Bir Araya Getirerek 
            <br/>
            Müşterilerimize Üstün Performans Ve Dayanıklılık Vaat Ediyoruz.
            </motion.p>
          </div>

          </div>

        </SwiperSlide> */}




      </Swiper>



    </div>
  )
}

export default Slider